import React, { useState, useRef } from 'react';
import its_a_pup from './Assets/its_a_pup.jpg';
import like_bees from './Assets/like_bees.jpg';
import dont_like_peas from './Assets/dont_like_peas.jpg';
import dont_like_sharks from './Assets/dont_like_sharks.jpg';
import dont_like_spiders from './Assets/dont_like_spiders.jpg';
import theres_a_giraffe from './Assets/look_theres_a_giraffe.jpg'
import its_ant from './Assets/its_an_ant.jpg'
import RecordRTC from 'recordrtc';
import { Close, DoneAll, GpsFixed, Hearing, Mic, MicExternalOff, Stop, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import pupaudio from "./Assets/audio/pup.mp3";
import antaudio from "./Assets/audio/ant.mp3";
import beeaudio from "./Assets/audio/bees.mp3";
import sharkaudio from "./Assets/audio/sharks.mp3";
import spideraudio from "./Assets/audio/spiders.mp3";
import giraffeaudio from "./Assets/audio/girrafe.mp3";
import peaaudio from "./Assets/audio/pees.mp3";

import {
  PICTURELINGO_PLAYGROUND_API,
  PICTURELINGO_PLAYGROUND_API_KEY
} from '../config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '40px',
  p: 6,
};

const styleLoader = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  borderRadius: '40px',
  p: 6,
};

function Recorder({ image, caption }) {
  const [recordedText, setRecordedText] = useState('');
  const [isCaptionHidden, setIsCaptionHidden] = useState(true);
  const [isRecording, setIsRecording] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const recorderRef = useRef(null);
  const audioRef = useRef(); 

  const handlePlayAudio = async () => {
    var urlaudio = null;
    if (image === 'imagepup') {
      urlaudio = pupaudio
    }  else if (image === 'imageant') {
      urlaudio = antaudio
    }  else if (image === 'imagelikebees') {
      urlaudio = beeaudio
    } else if (image === 'imagedontlikepeas') {
      urlaudio = peaaudio
    } else if (image === 'imagedontlikesharks') {
      urlaudio = sharkaudio
    } else if (image === 'imagedontlikespiders') {
      urlaudio = spideraudio
    } else if (image === 'imagetheresgiraffe') {
      urlaudio = giraffeaudio
    }
    const playaudio = new Audio(urlaudio)
    playaudio.play();
  };
  

  const handleRecord = async () => {
    try {
      await handlePlayAudio();
      setTimeout(async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new RecordRTC(stream, { type: 'audio' });
        recorderRef.current = recorder;
  
        setIsRecording(true);
        recorder.startRecording();
  
        setTimeout(async () => {
          await handleStopRecording();
        }, 5000);
      }, 3000);
    } catch (error) {
      alert('Error accessing microphone: ' + error.message);
    }
  };
  
  const handleStopRecording = async () => {
    recorderRef.current.stopRecording(async () => {
      setIsRecording(false);
      const audioBlob = recorderRef.current.getBlob();
  
      try {
        const formData = new FormData();
        formData.append('audio_file', new File([audioBlob], 'recorded_audio.m4a'));
        formData.append('pagecaption', caption);
        setIsLoading(true);
  
        const response = await fetch(`${PICTURELINGO_PLAYGROUND_API}${PICTURELINGO_PLAYGROUND_API_KEY}`, {
          method: 'POST',
          body: formData,
        });

        console.log(`${PICTURELINGO_PLAYGROUND_API}${PICTURELINGO_PLAYGROUND_API_KEY}`)
  
        if (response.ok) {
          const data = await response.json();
          setShowModal(true);
          setModalData(data);
        } else {
          alert('Error uploading audio and caption: ' + response.statusText);
        }
      } catch (error) {
        alert('Error handling recording: ' + error.message);
      } finally {
        setIsLoading(false);
      }
    });
  };
  

  const toggleCaptionVisibility = () => {
    setIsCaptionHidden(!isCaptionHidden);
  };

  let renderedImage;

  if (image === 'imagepup') {
    renderedImage = <img src={its_a_pup} alt={caption} />;
  }  else if (image === 'imageant') {
    renderedImage = <img src={its_ant} alt={caption} />;
  }  else if (image === 'imagelikebees') {
    renderedImage = <img src={like_bees} alt={caption} />;
  } else if (image === 'imagedontlikepeas') {
    renderedImage = <img src={dont_like_peas} alt={caption} />;
  } else if (image === 'imagedontlikesharks') {
    renderedImage = <img src={dont_like_sharks} alt={caption} />;
  } else if (image === 'imagedontlikespiders') {
    renderedImage = <img src={dont_like_spiders} alt={caption} />;
  } else if (image === 'imagetheresgiraffe') {
    renderedImage = <img src={theres_a_giraffe} alt={caption} />;
  }

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const getColorClass = (value) => {
    if (value >= 80) {
      return 'green-text';
    } else if (value >= 50) {
      return 'orange-text';
    } else {
      return 'red-text';
    }
  };


  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isCaptionHidden ? (
          <p className='overlayText'></p>
        ) : (
          <p className='overlayText'>{caption}</p>
        )}
        {renderedImage}
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            {isCaptionHidden ? (
              <>
                <IconButton className='recorderBtn' onClick={toggleCaptionVisibility} style={{ backgroundColor: 'blue', color: 'white', borderRadius: '25px', padding: '10px' }}>
                  <Visibility />
                </IconButton>
                <Typography>SEE CAPTION</Typography>
              </>
            ) : (
              <>
                <IconButton className='recorderBtn' onClick={toggleCaptionVisibility} style={{ backgroundColor: 'blue', color: 'white', borderRadius: '25px', padding: '10px' }}>
                  <VisibilityOff />
                </IconButton>
                <Typography>HIDE CAPTION</Typography>

              </>
            )}
          </Grid>
          <Grid item xs={4}>
          <IconButton
              onClick={handlePlayAudio}
              style={{
                backgroundColor: 'green',
                color: 'white',
                borderRadius: '25px',
                padding: '10px',
              }}
            >
              <Hearing/>
            </IconButton>
            <Typography>HEAR AUDIO</Typography>
              
          </Grid>
          <Grid item xs={4}>
            <IconButton
              className={`recorderBtn ${isRecording ? 'recording' : ''}`}
              onClick={isRecording ? handleStopRecording : handleRecord}
              style={{
                backgroundColor: isRecording ? 'white' : 'red',
                color: isRecording ? 'red' : 'white',
                borderRadius: '25px',
                padding: '10px',
              }}
            >
              {isRecording ? <Stop /> : <Mic />}
            </IconButton>
            <Typography>{isRecording ? 'STOP RECORDING' : 'RECORD AUDIO'}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={isLoading} className='modal'>
        <Box sx={styleLoader}>
          <CircularProgress color="success"/>
        </Box>
      </Modal>
      <Modal open={showModal} onClose={closeModal} className='modal'>
        <Box sx={style}>
          <div>
            <h4>Recording Results<br></br>تسجيل النتائج</h4>
            {modalData && (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className=''><GpsFixed />ACCURACY<br></br> دقة</span><br></br>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <span className={`perc ${getColorClass(modalData.bowlofbags)}`}>
                      {Math.round(modalData.bowlofbags)}
                    </span><br></br>
                    <span className={`perc ${getColorClass(modalData.jaccard_similarity)}`}>
                      {Math.round(modalData.jaccard_similarity)}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField value={(modalData.audiocaption)} disabled/>
                  </Grid>
                </Grid>
              </div>
            )}
            <Button onClick={closeModal}><Close sx={{color:'red'}}/></Button>
          </div>
        </Box>
      </Modal>
    </Grid>
    </>
  );
}

export default Recorder;
