import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './swiper.css';
import { Mousewheel, Pagination } from 'swiper/modules';
import Recorder from '../Recorder/recorder';

import InitalPage from '../Screen/intialpage';
import storybookAssets from '../Screen/storybook_data';
import { EffectCards } from 'swiper/modules';
import 'swiper/css/effect-cards';
import ConclusionPage from '../Screen/conclusion'

export default function SwiperAnim() {
  return (
    <>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        <SwiperSlide><InitalPage/></SwiperSlide>
        {storybookAssets.map((item) => (
          <SwiperSlide key={item.id}>
          <Recorder image={item.image} caption={item.caption} audio={item.audio}/>
        </SwiperSlide>
        ))}
        <SwiperSlide><ConclusionPage/></SwiperSlide>
      </Swiper>
    </>
  );
}
